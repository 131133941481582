export const FETCH_REGIONS = 'fetch_regions';
export const FETCH_REGIONS_ERR = 'fetch_regions_err';
export const CREATE_REGION = 'create_region';
export const CREATE_REGION_ERR = 'create_region_err';
export const UPDATE_REGION = 'update_region';
export const UPDATE_REGION_ERR = 'update_region_err';

export const FETCH_SITES = 'fetch_sites';
export const FETCH_SITES_ERR = 'fetch_sites_err';
export const CREATE_SITE = 'create_site';
export const CREATE_SITE_ERR = 'create_site_err';
export const UPDATE_SITE = 'update_site';
export const UPDATE_SITE_ERR = 'update_site_err';
export const DELETE_SITE = 'delete_site';
export const DELETE_SITE_ERR = 'delete_site_err';

export const FETCH_SUBSITES = 'fetch_subsites';
export const FETCH_SUBSITES_ERR = 'fetch_subsites_err';

export const FETCH_SITE_INFO = 'fetch_site_info';
export const FETCH_SITE_INFO_ERR = 'fetch_site_info_err';

export const APPROVE_USER = 'approve_user';
export const APPROVE_USER_ERR = 'approve_user_err';
export const DENY_USER = 'approve_user';
export const DENY_USER_ERR = 'approve_user_err';
export const DELETE_USER = 'delete_user';
export const DELETE_USER_ERR = 'delete_user_err';
export const FETCH_USER = 'fetch_user'
export const FETCH_USER_ERR = 'fetch_user_err'
export const UPDATE_USER = 'update_user'
export const UPDATE_USER_ERR = 'update_user_err'

export const FETCH_GROUPS = 'fetch_groups';
export const FETCH_GROUPS_ERR = 'fetch_groups_err';
export const FETCH_SITE_GROUPS = 'fetch_site_groups';
export const FETCH_SITE_GROUPS_ERR = 'fetch_site_groups_err';
export const CREATE_GROUP = 'create_group';
export const CREATE_GROUP_ERR = 'create_group_err';
export const UPDATE_GROUP = 'update_group';
export const UPDATE_GROUP_ERR = 'update_group_err';
export const DELETE_GROUP = 'delete_group';
export const DELETE_GROUP_ERR = 'delete_group_err';

export const FETCH_GROUP_TYPES = 'fetch_group_types';
export const FETCH_GROUP_TYPES_ERR = 'fetch_group_types_err';
export const CREATE_GROUP_TYPE = 'create_group_type';
export const CREATE_GROUP_TYPE_ERR = 'create_group_type_err';
export const UPDATE_GROUP_TYPE = 'update_group_type';
export const UPDATE_GROUP_TYPE_ERR = 'update_group_type_err';
export const DELETE_GROUP_TYPE = 'delete_group_type';
export const DELETE_GROUP_TYPE_ERR = 'delete_group_type_err';

export const FETCH_COUNTRIES = 'fetch_countries';
export const FETCH_COUNTRIES_ERR = 'fetch_countries_err';

export const FETCH_SITE_TYPES = 'fetch_site_types';
export const FETCH_SITE_TYPES_ERR = 'fetch_site_types_err';

export const FETCH_COMMODITIES = 'fetch_commodities';
export const FETCH_COMMODITIES_ERR = 'fetch_commodities_err';

export const FETCH_GROUP_SITES = 'fetch_group_sites';
export const FETCH_GROUP_SITES_ERR = 'fetch_group_site_err';
export const ADD_GROUP_SITES = 'add_group_sites';
export const ADD_GROUP_SITES_ERR = 'add_group_site_err';
export const DELETE_ALL_GROUP_SITES = 'delete_all_group_sites';
export const DELETE_ALL_GROUP_SITES_ERR = 'delete_all_group_site_err';

export const FETCH_GROUP_SECURITY_GROUPS = 'fetch_pseudo_group_security_groups';
export const FETCH_GROUP_SECURITY_GROUPS_ERR = 'fetch_pseudo_group_security_groups_err';
export const CREATE_GROUP_SECURITY_GROUP = 'create_pseudo_group_security_group';
export const CREATE_GROUP_SECURITY_GROUP_ERR = 'create_pseudo_group_security_group_err';
export const UPDATE_GROUP_SECURITY_GROUP = 'update_pseudo_group_security_group';
export const UPDATE_GROUP_SECURITY_GROUP_ERR = 'update_pseudo_group_security_group_err';
export const DELETE_GROUP_SECURITY_GROUP = 'delete_pseudo_group_security_group';
export const DELETE_GROUP_SECURITY_GROUP_ERR = 'delete_pseudo_group_security_group_err';

export const FETCH_USERS = 'fetch_users_staging';
export const FETCH_USERS_ERR = 'fetch_users_staging_err';
export const ENABLE_USER_STAGING = 'enable_user_staging';
export const ENABLE_USER_STAGING_ERR = 'enable_user_staging_err';
export const DISABLE_USER_STAGING = 'disable_user_staging';
export const DISABLE_USER_STAGING_ERR = 'disable_user_staging_err';
export const DELETE_USER_STAGING = 'delete_user_staging';
export const DELETE_USER_STAGING_ERR = 'delete_user_staging_err';
export const REMOVE_USER_FROM_GROUP_STAGING = 'remove_user_from_group_staging';
export const REMOVE_USER_FROM_GROUP_STAGING_ERR = 'remove_user_from_group_staging_err';
export const ADD_USER_TO_GROUP_STAGING = 'add_user_to_group_staging';
export const ADD_USER_TO_GROUP_STAGING_ERR = 'add_user_to_group_staging_err';

export const FETCH_USERS_PRODUCTION = 'fetch_users_production';
export const FETCH_USERS_PRODUCTION_ERR = 'fetch_users_production_err';
export const ENABLE_USER_PRODUCTION = 'enable_user_production';
export const ENABLE_USER_PRODUCTION_ERR = 'enable_user_production_err';
export const DISABLE_USER_PRODUCTION = 'disable_user_production';
export const DISABLE_USER_PRODUCTION_ERR = 'disable_user_production_err';
export const DELETE_USER_PRODUCTION = 'delete_user_production';
export const DELETE_USER_PRODUCTION_ERR = 'delete_user_production_err';
export const REMOVE_USER_FROM_GROUP_PRODUCTION = 'remove_user_from_group_production';
export const REMOVE_USER_FROM_GROUP_PRODUCTION_ERR = 'remove_user_from_group_production_err';
export const ADD_USER_TO_GROUP_PRODUCTION = 'add_user_to_group_production';
export const ADD_USER_TO_GROUP_PRODUCTION_ERR = 'add_user_to_group_production_err';
export const CLEAR_USER_PROCESS = 'clear_user_process';
